<template>
    <div class="container-fluid">
        <base-header class="py-4">
            <route-breadcrumb />
        </base-header>

        <div class="card" v-loading="isLoading">
            <div class="card-header">
                <div class="d-flex">
                    <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.search')}}</h3>
                </div>
            </div>

            <ul class="list-group list-group-flush flex-fill m-5" data-toggle="checklist">
                <li v-for="item in items" class="list-group-item px-0 clickable" @click="click(item)">
                    <div class="row align-items-center">
                        <div class="col ml--2">
                            <h4 class="mb-0">{{item.name}}</h4>
                        </div>
                        <div class="col-auto">
                            {{item.type}}
                        </div>
                    </div>
                </li>
            </ul>


            <ul class="pagination pagination-lg align justify-content-center">
                <li class="page-item prev-page" :class="{disabled: from === 0}">
                    <a class="page-link" aria-label="Previous" @click="page(-1)">
                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                    </a>
                </li>
                <li class="page-item next-page" :class="{disabled: items.length != size}">
                    <a class="page-link" aria-label="Next" @click="page(+1)">
                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                    </a>
                </li>
            </ul>


        </div>
    </div>
</template>
<script>

  import search from '@/util/search.js'
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'search',
    computed: {
      ...mapGetters([
        "currentUser",
        'currentUserRights'
      ])
    },
    data() {
      return {
        from: 0,
        size: 10,
        items: [],
        currentPage: 1,
        isLoading: false
      };
    },
    watch: {
      '$route' () {
        this.page(0)
      }
    },
    methods: {
      async page(dir) {
        this.from += dir*this.size;
        this.isLoading = true;
        const items = await search(this.$route.params.searchText, this.from, this.size);
      
        this.items = items.filter(i => {
          if (i.type === 'task' && !this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen)')) {
            return false
          }
          return true
        })

        this.isLoading = false
      },
      click(item) {
        if (item.ctx) {
          this.$store.commit('searchCtx', item.ctx)
        }

        this.$router.push(item.route)
      }
    },
    async mounted() {
      this.page(0)
    }
  }
</script>
